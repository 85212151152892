.table-wrapper {
    display: grid;
    grid-template-rows: 1fr 20fr .1fr 2fr;
    width: 98%;
    height: 1000px;
    /* margin-top: 50px; */
    margin: 0 auto;
}

.species-table {
    width: 100%;
}


.trucate-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

