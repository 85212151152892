

.ml-cell-root {
    height: 400px;
    /* padding: 10px; */
    /* background-color: brown; */
}
.ml-cell-title {
    height: 10%;
    /* background-color: blue; */
}
.ml-cell-body {
    height: 80%;
    /* background-color: yellow; */
}
.ml-cell-caption {
    height: 10%;
    /* background-color: green; */
}