

.eda-row {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    width: 100%;
    height: 450px;


    /* background: rgba(15, 14, 71, 0.3);
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(20px);
    color: white; */
}




.stat-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; 
    width: 100%;
    justify-content: center;
    align-content: center;
}




.scatter-panel-row {
    border: 0.5px solid rgba(33, 33, 33, 0.25);
    border-radius: 5px;
    margin: 5px;

}

.scatter-controls {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    row-gap: 20px;
    padding: 20px;
    width: 100%;
    justify-content: center;
    align-content: center;
}

.scatter-controls-wide {
    display: grid;
    grid-template-columns: 1fr; 
    row-gap: 20px;
    padding: 20px;
    width: 100%;
    justify-content: center;
    align-content: center;
}