.species-options-container {
    margin-top: 70px;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
}

.fieldset-species-filters {
    margin-bottom: 20px;
    border-color: rgba(0,0,0,0.3);
    border-width: 1px;
}


.species-image {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.species-tab-container {
    /* margin-top: 100px; */
}

.species-tab-panel {

}